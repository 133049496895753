.input {
  transition: 0.3s border-color;
}

.input:hover,
.input:active,
.input:focus {
  background: var(--white);
  border: 1px solid var(--blue-50);
  box-shadow: 0px 0px 0px 4px rgba(22, 0, 229, 0.05);
  outline: none;
}

.select_container {
  max-width: 88px;
  transition: 0.3s border-color;
}

.select_input {
  border: 1px solid var(--gray-40);
  border-right: 0;
  border-radius: 8px 0 0 8px;
  width: 100%;
  height: 48px;
  padding: 0 1rem;
}

.select_input:hover,
.select_input:active,
.select_input:focus {
  background: var(--white);
  border: 1px solid var(--blue-50);
  box-shadow: 0px 0px 0px 4px rgba(22, 0, 229, 0.05);
  outline: none;
}

.select_options {
  border: 1px solid var(--gray-40);
  border-radius: 4px;
  background: white;
  position: absolute;
  max-width: 88px;
  width: 100%;
  max-height: 250px;
  overflow: auto;
}

.select_option {
  padding: 0.5rem 1rem;
}
