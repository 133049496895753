.accordion :global(.active) {
  font-weight: 500;
}
.accordion :global(.accordion__header) {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.accordion :global(.accordion__title) {
  font-family: var(--font-articulat-cf);
  color: white;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 500;
}

.accordion :global(.active.accordion__header .accordion__title) {
  font-weight: 600;
}

.icon {
  transition: all 300ms linear;
}

.icon:global(.active) {
  transform: rotate(-180deg);
  transition: all 300ms linear;
}

@media (min-width: 768px) {
  .accordion :global(.accordion__title) {
    font-size: 24px;
    line-height: 1.2;
  }
}

.accordion :global(.accordion__content) {
  border-bottom: 1px solid rgba(0, 176, 204, 0.13);
  transition: all 0.5s linear;
  overflow: hidden;
}
.accordion :global(.accordion__content--expanded) {
  border-bottom: 3px solid var(--cyan-70);
  transition: all 0.5s linear;
}
.accordion:last-child :global(.accordion__content) {
  border-bottom: none;
}

.scheduleList {
  animation: fade 800ms ease;
}

@keyframes fade {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
@media (min-width: 768px) {
  .accordion :global(.active.accordion__header .accordion__title) {
    font-weight: 600;
  }
  .accordion :global(.active.accordion__header) {
    margin-bottom: 0.5rem;
    transition: all 0.4s linear;
  }
  .accordion :global(.accordion__header) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    transition: all 0.4s linear;
  }
}

.contentContainer {
  animation: fade-in 500ms ease forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  25% {
    opacity: 0;
    transform: translateX(30px);
  }
  85%,
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.sideLineFadeIn {
  animation: sidelineAnimationIn 800ms ease forwards;
}

@keyframes sidelineAnimationIn {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

.sideLineFadeOut {
  animation: sidelineAnimationOut 800ms ease forwards;
}

@keyframes sidelineAnimationOut {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}

.fadeIn {
  animation: fadeIn 2000ms ease forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  85%,
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.fadeOut {
  animation: fadeOut 1000ms ease forwards;
}

@keyframes fadeOut {
  0%,
  15% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(30px);
  }
}

.animatedLine {
  transition: height 0.5s ease;
}

.animatedLine.visible {
  height: 100%;
}

.animatedLine.hidden {
  height: 0;
}
