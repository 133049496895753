.registerBtn {
  color: var(--blue-web-90);
  background-color: var(--yellow-60);
}
.registerBtn:hover {
  background-color: var(--yellow-50);
}

.formField {
  align-items: unset;
  width: 100%;
}

.formField label {
  color: var(--blue-web-90);
  font-size: 16px !important;
  font-weight: 500;
}

.formField input {
  color: black;
  height: 3rem;
  border-color: var(--gray-40);
}

.formField input::placeholder {
  color: var(--gray-50);
}
