.hoverBorder {
  position: relative;
}

.hoverBorder::after,
.activeBorder::after {
  content: '';
  position: absolute;
  height: 4px;
  bottom: 0;
  background: var(--green-40);
}

.hoverBorder::after {
  width: 0%;
  left: 50%;
  transition: all 300ms ease-in-out;
}

.activeBorder::after {
  width: 100%;
  left: 0%;
}

.hoverBorder:hover::after {
  left: 0%;
  width: 100%;
}

.animate {
  animation: animate 500ms ease-in;
}

@keyframes animate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animateImage {
  animation: animateImage 800ms ease-in;
}

@keyframes animateImage {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.contentContainer {
  animation: fade-in 500ms ease forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  25% {
    opacity: 0;
    transform: translateX(30px);
  }
  85%,
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.sideLineFadeIn {
  animation: sidelineAnimationIn 800ms ease forwards;
}

@keyframes sidelineAnimationIn {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

.sideLineFadeOut {
  animation: sidelineAnimationOut 800ms ease forwards;
}

@keyframes sidelineAnimationOut {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}

.fadeIn {
  animation: fadeIn 2000ms ease forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  85%,
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.fadeOut {
  animation: fadeOut 1000ms ease forwards;
}

@keyframes fadeOut {
  0%,
  15% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(30px);
  }
}

.animatedLine {
  transition: height 0.5s ease;
}

.animatedLine.visible {
  height: 100%;
}

.animatedLine.hidden {
  height: 0;
}
