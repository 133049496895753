.input {
  transition: 0.3s border-color;
}

.input:hover,
.input:active,
.input:focus {
  background-color: var(--white);
  border: 1px solid var(--blue-50);
  box-shadow: 0px 0px 0px 4px rgba(22, 0, 229, 0.05);
  outline: none;
}

.input_cmp_form {
  border: none;
  background-color: #1c183a !important;
  color: #fff;
  padding: 0 16px;
}

.input_cmp_form:hover,
.input_cmp_form:active,
.input_cmp_form:focus {
  background-color: #1c183a !important;
  border: none !important;
  box-shadow: none;
  outline: none;
}
