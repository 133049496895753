.toastRoot[data-state='open'] {
  animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.toastRoot[data-state='closed'] {
  animation: hide 100ms ease-in;
}

.toastRoot[data-swipe-direction='down'] {
  position: fixed;
  width: 100%;
  bottom: 8%;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.toastRoot[data-swipe-direction='up'] {
  position: fixed;
  width: 100%;
  top: 8%;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  animation: slideDown 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
