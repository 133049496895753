.select {
  background: white;
  margin-top: 0.25rem;
  position: absolute;
  overflow-y: auto;
  max-height: 350px;
  width: 100%;
  z-index: 10;
  transition: opacity 150ms ease-in;
}

.search_container {
  position: relative;
}

.search_container::after {
  content: '';
  background: url('/assets/icons/form/select/zoom.svg');
  position: absolute;
  right: 1rem;
  top: 11px;
  width: 16px;
  height: 16px;
}

.container:not(.has-focus) .select {
  visibility: hidden;
  opacity: 0;
}

.input {
  border-radius: 8px;
  background: var(--gray-10);
  border: 1px solid var(--gray-20);
  padding: 0 16px;
  min-height: 40px;
  width: 100%;
  transition: 0.3s border-color;
}

.input:hover,
.input:active,
.input:focus {
  background: var(--white);
  border: 1px solid var(--blue-50);
  border-radius: 8px;
  box-shadow: 0px 0px 0px 4px rgba(22, 0, 229, 0.05);
  outline: none;
}

.options {
  list-style: none;
  overflow: auto;
}

.row {
  display: block;
}

.group {
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--gray-30);
}

.group-header {
  color: var(--gray-60);
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  font-size: 11px;
  height: 32px;
  padding: 0 1rem;
  text-transform: uppercase;
}

.option {
  background-color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: block;
  position: relative;
  font-size: 14px;
  padding: 0 16px;
  min-height: 32px;
  outline: none;
  width: 100%;
  text-align: left;
}

.option.is-selected {
  background: var(--blue-10);
  color: var(--blue-50);
  font-weight: 500;
}

.option.is-selected::after {
  content: '';
  background-image: url('/assets/icons/form/select/tick.svg');
  background-repeat: no-repeat;
  height: 12px;
  width: 12px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
