.select {
  background: #1c183a !important;
  color: #fff;
  margin-top: 0.25rem;
  position: absolute;
  overflow-y: auto;
  max-height: 500px;
  width: 100%;
  z-index: 10;
}

.input {
  border-radius: 8px;
  background: #1c183a !important;
  color: #fff;
  border: none;
  padding: 0 16px;
  min-height: 40px;
  width: 100%;
  transition: 0.3s border-color;
}

.input:hover,
.input:active,
.input:focus {
  border-radius: 8px;
  outline: none;
  background-color: #1c183a;
  border: none !important;
  box-shadow: none;
}

.options {
  list-style: none;
  overflow: auto;
}

.row {
  display: block;
}

.option {
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: block;
  position: relative;
  font-size: 14px;
  padding: 0 16px;
  min-height: 32px;
  outline: none;
  width: 100%;
  text-align: left;
}

.option.is-selected {
  background: var(--blue-10);
  color: var(--blue-50);
  font-weight: 500;
}

.option.is-selected::after {
  content: '';
  background-image: url('/assets/icons/form/select/tick.svg');
  background-repeat: no-repeat;
  height: 12px;
  width: 12px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
