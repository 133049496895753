.logoSlider:nth-child(1) > *:nth-child(-n + 6) {
  display: block;
}

@media (min-width: 640px) {
  .logoSlider {
    --animation-duration: calc(2s * var(--content-types));
    animation: marquee linear infinite;
  }

  .gradientAtMargin:hover .logoSlider {
    animation-play-state: paused;
  }
  .gradientAtMargin::before,
  .gradientAtMargin::after {
    content: '';
    position: absolute;
    width: 64px;
    height: 100%;
    top: 0;
  }
  .gradientColorAtDark::before {
    background: linear-gradient(90deg, #070425 20%, #07042500 100%);
    z-index: 10;
    left: 0px;
  }
  .gradientColorAtDark::after {
    background: linear-gradient(270deg, #070425 0%, #07042500 100%);
    right: 0px;
  }
  .gradientColorAtDefault::before {
    background: linear-gradient(90deg, #2c0d62 20%, #2c0d6200 100%);
    z-index: 10;
    left: 0px;
  }
  .gradientColorAtDefault::after {
    background: linear-gradient(270deg, #2c0d62 0%, #2c0d6200 100%);
    right: 0px;
  }

  .logoSlider:nth-child(1) > *:nth-child(-n + 6) {
    display: hidden;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
